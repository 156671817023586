import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import "./game.css";
import Header from "../components/Header";

function Sicbo() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
        }
        if (loading == true) return;
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launch`, form)
            .then((res) => {
                if (res.data.data.errMsg == "SUCCESS") {
                    window.open(res.data.data.gameUrl, "_blank");
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
                setLoading(false);
            });
    }
    const [yoGame, setYoGame] = useState(false);
    const [yoPopup, setYoPopup] = useState(false);
    const [yoType, setYoType] = useState(1);
    const [yoMoney, setYoMoney] = useState(0);
    const [yoBalance, setYoBalance] = useState(0);
    const openYoGame = () => {
        if (profile === null) {
            return navigate("/login");
        }
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/logingame`)
            .then((res) => {
                setYoGame(res.data.data);
                setYoBalance(res.data.data?.AccountInfo?.Balance);
                setYoPopup(true);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
            });
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitYoGame = async () => {
        if (profile === null) {
            return navigate("/login");
        }
        if (isSubmitting) {
            return swal("Thông báo", "Bạn thao tác quá nhanh", "warning");
        }
        setLoading(true);
        const transactionAmount = Number(yoMoney?.replaceAll(".", "")?.replaceAll(",", ""));
        if (transactionAmount < 0) {
            setLoading(false);
            return swal("Chú ý", "Vui lòng nhập số tiền cần giao dịch", "warning");
        }
        const formData = {
            amount: transactionAmount,
            type: yoType
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/transfergame`, formData)
            .then((res) => {
                axios
                    .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                    .then((res) => {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    })
                    .catch((err) => localStorage.removeItem("user"));
                swal(yoType == 1 ? "Nạp tiền thành công" : "Rút tiền thành công", "", "success");
                setYoBalance(res.data.data.new_balance);
                setTimeout(() => {
                    setIsSubmitting(false);
                }, 3000);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
                setIsSubmitting(false);
            });
    };
    return (
        <>
            <Header profile={profile} />
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-sicbo">
                <div className="container">
                    <div className="section-title2" style={{ color: "#fff", marginTop: "0" }}>
                        VIVO88
                    </div>
                    <div className="menu-box-game box-card-game" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }}>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../images/mb75s.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Xổ số nhanh 75s</div>
                                <div onClick={() => navigate("/xs75s/lo2")} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../images/st2p.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Xổ số nhanh 120s</div>
                                <div onClick={() => navigate("/xs120s/lo2")} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/dice.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/sd.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Xóc đĩa</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/slotLs.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Rồng hổ</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/slotXy.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/cardSss.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/mini_poker.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/bigSmallMD5.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/dx.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/yxx.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/slotCs.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/cardPhom.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/bjl.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                        <div className="card-game">
                            <div className="card-game-image">
                                <img src={require("../../image/roshambo.png")} />
                            </div>
                            <div className="card-game-info">
                                <div className="card-game-title">Sicbo</div>
                                <div onClick={openYoGame} className="card-game-play">
                                    Vào trò chơi
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {yoPopup === true && yoGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setYoPopup(false)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">VIVO88</div>
                        <div className="popup-content">
                            <ul className="tab-navigation tab-game">
                                <li className={yoType === 1 ? "active" : ""} onClick={() => setYoType(1)}>
                                    Nạp tiền
                                </li>
                                <li className={yoType === 2 ? "active" : ""} onClick={() => setYoType(2)}>
                                    Rút tiền
                                </li>
                            </ul>
                            <div className="form-sibo">
                                <input
                                    type="text"
                                    value={yoMoney}
                                    onClick={() => setYoMoney(null)}
                                    onChange={(e) => setYoMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    placeholder="Nhập số tiền"
                                />
                                <button onClick={submitYoGame}>Xác nhận</button>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư YoGame: <b>{yoBalance?.toLocaleString("vi-VN")}</b>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <button className="open-game" onClick={() => (window.location.href = `https://game.link-mb66.com?token=${yoGame?.Token}`)}>
                                Vào game
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Sicbo;

import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../components/Header";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperGame5 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 5.4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/miba`).then((res) => {
            setBet(res.data.t);
            if (bet != res.data.t) {
                setBet(res.data.t);
                localStorage.setItem("xsmb", JSON.stringify(res.data.t));
            }
        });
        axios.post(`${process.env.REACT_APP_API_URL}/casino/create`, {});
    }, []);
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const handleChangeMT = (e) => {
        setDateMT(e.target.value);
    };
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    const handleChangeMN = (e) => {
        setDateMN(e.target.value);
    };
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return;
        }
        if (loading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "174":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "EG";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const [profile, setProfile] = useState(null);
    const [playGame, setPlayGame] = useState(null);
    const { register, handleSubmit } = useForm();
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [product, setProduct] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    const menuGame = (title, product_type, game_type) => {
        setTitle(title);
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getgamelist`, { product_type, game_type })
            .then((res) => {
                if (res.data.data.length == 0) {
                    swal("Thông báo", "Sảnh game bảo trì", "error");
                    setLoading(false);
                } else {
                    setGame(res.data.data[0]);
                    setLoading(false);
                    setShow(true);
                }
            })
            .catch((err) => {
                swal("Thông báo", "Sảnh game bảo trì", "error");
                setLoading(false);
            });
    };
    const [yoGame, setYoGame] = useState(false);
    const [yoPopup, setYoPopup] = useState(false);
    const [yoType, setYoType] = useState(1);
    const [yoMoney, setYoMoney] = useState(0);
    const [yoBalance, setYoBalance] = useState(0);
    const openYoGame = () => {
        if (profile === null) {
            return navigate("/login");
        }
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/logingame`)
            .then((res) => {
                setYoGame(res.data.data);
                setYoBalance(res.data.data?.AccountInfo?.Balance);
                setYoPopup(true);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
            });
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitYoGame = async () => {
        if (profile === null) {
            return navigate("/login");
        }
        if (isSubmitting) {
            return swal("Thông báo", "Bạn thao tác quá nhanh", "warning");
        }
        setLoading(true);
        const transactionAmount = Number(yoMoney?.replaceAll(".", "")?.replaceAll(",", ""));
        if (transactionAmount < 0) {
            setLoading(false);
            return swal("Chú ý", "Vui lòng nhập số tiền cần giao dịch", "warning");
        }
        const formData = {
            amount: transactionAmount,
            type: yoType
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/transfergame`, formData)
            .then((res) => {
                axios
                    .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                    .then((res) => {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    })
                    .catch((err) => localStorage.removeItem("user"));
                swal(yoType == 1 ? "Nạp tiền thành công" : "Rút tiền thành công", "", "success");
                setYoBalance(res.data.data.new_balance);
                setTimeout(() => {
                    setIsSubmitting(false);
                }, 3000);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
                setIsSubmitting(false);
            });
    };
    return (
        <>
            <Header profile={profile} />
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-black">
                {swiperParams && notify && (
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                    </div>
                )}
                <div className="container">
                    <div className="section-title2">VIVO88</div>
                    <Swiper {...swiperGame5}>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_baucua.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_tx.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_xocdia.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Link to="/xs75s/lo2">
                                <img alt="" src={require(`../../images/mb75s.png`)} />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Link to="/xs120s/lo2">
                                <img alt="" src={require(`../../images/st2p.png`)} />
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_txmd5.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_sicbo.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_minpoker.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_baccarat.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_maubinh.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_thantai.png")} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div onClick={openYoGame} className="open-yogame">
                                <img src={require("../../images/dx_tayduky.png")} />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="container" style={{ margin: "30px auto 0", maxWidth: "1320px" }}>
                    <div className="section-title" onClick={() => navigate("/lottery")}>
                        Trò Chơi
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "15px" }}>
                        <div className="box-miba">
                            <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")} style={{ width: "30%" }}>
                                <img alt="" src={require("../../images/xs3mien/mienbac.png")} style={{ maxWidth: "120px" }} />
                            </div>
                            <div className="box-miba-right" style={{ width: "70%", paddingLeft: "20px" }}>
                                <div className="box-text" style={{ margin: "10px 0" }}>
                                    Miền Bắc
                                </div>
                                <div style={{ margin: "10px", color: "#000" }}>Ngày: {bet?.issueList[0]?.turnNum}</div>
                                <div className="box-miba-kq">
                                    {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                        <div className="ball">
                                            <img alt="" src={`/images/ball/${x}.png`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="box-miba">
                            <div className="box-miba-titlev">Miền Nam</div>
                            <div className="list-game-xs">
                                {dataMN?.map((item, index) => (
                                    <>
                                        <Link to={`/xsmn/lo2/${item}`} key={item} style={{ display: "block", width: "33.33%" }}>
                                            <img alt="" src={require(`../../images/xs3mien/${imageMN[index]}.png`)} />
                                        </Link>
                                    </>
                                ))}
                            </div>
                        </div>
                        <div className="box-miba">
                            <div className="box-miba-titlev">Miền Trung</div>
                            <div className="list-game-xs">
                                {dataMT?.map((item, index) => (
                                    <>
                                        <Link to={`/xsmt/lo2/${item}`} key={item} style={{ display: "block", width: "33.33%" }}>
                                            <img alt="" src={require(`../../images/xs3mien/${imageMT[index]}.png`)} />
                                        </Link>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="super-title">
                        <div className="super-title-left">
                            <img src="/images/left.png.avif" />
                        </div>
                        <div className="super-title-center">
                            <div className="super-title-big">GAME CỰC CHẤT</div>
                            <div className="super-title-small">GAME CỰC HOT, CHƠI CỰC ĐỈNH</div>
                        </div>
                        <div className="super-title-right">
                            <img src="/images/left.png.avif" style={{ transform: "rotateY(180deg)" }} />
                        </div>
                    </div>
                    <div className="grid-3">
                        <div className="super-big-item pointer" onClick={() => navigate("/casino")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/big_bj_39636407564188.png.avif" loading="lazy" />
                                <div className="item_but">CASINO</div>
                            </div>
                            <div className="figure_cont">
                                <img className="app-image figure" src="/images/figure_39636407564188.png.avif" loading="lazy" />
                            </div>
                        </div>
                        <div className="super-big-item pointer" onClick={() => navigate("/sports")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/big_bj_362857114342923387.png.avif" loading="lazy" />
                                <div className="item_but">THỂ THAO</div>
                            </div>
                            <div className="figure_cont">
                                <img className="app-image figure" src="/images/figure_362857114342923387.png.avif" loading="lazy" />
                            </div>
                        </div>
                        <div className="super-big-item pointer" onClick={() => navigate("/lottery")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/big_bj_8840968486572375835.png.avif" loading="lazy" />
                                <div className="item_but">LÔ ĐỀ</div>
                            </div>
                            <div className="figure_cont">
                                <img className="app-image figure" src="/images/figure_8840968486572375835.png.avif" loading="lazy" />
                            </div>
                        </div>
                    </div>
                    <div className="grid-5">
                        <div className="super-small-item pointer" onClick={() => navigate("/sicbo")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_41465988833800.png.avif" loading="lazy" />
                                <div className="item_but">TÀI XỈU</div>
                            </div>
                            <img className="app-image figure" src="/images/taixiu.png" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/pvp")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_16564048344271.png.avif" loading="lazy" />
                                <div className="item_but">GAME BÀI</div>
                            </div>
                            <img className="app-image figure" src="/images/subclass41465988833800.png.avif" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/slots")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_6861705028422769039.png.avif" loading="lazy" />
                                <div className="item_but">NỔ HŨ</div>
                            </div>
                            <img className="app-image figure" src="/images/subclass6861705028422769039.png.avif" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/fishing")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_44196810703047.png.avif" loading="lazy" />
                                <div className="item_but">BẮN CÁ</div>
                            </div>
                            <img className="app-image figure" src="/images/subclass44196810703047.png.avif" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/sicbo")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_16754997519210.png.avif" loading="lazy" />
                                <div className="item_but">XÓC ĐĨA</div>
                            </div>
                            <img className="app-image figure" src="/images/xocdia.png" loading="lazy" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="super-title">
                        <div className="super-title-left">
                            <img src="/images/left.png.avif" />
                        </div>
                        <div className="super-title-center" style={{ width: "35%" }}>
                            <div className="super-title-big">DỊCH VỤ CHẤT LƯỢNG</div>
                            <div className="super-title-small">AN TOÀN ỔN ĐỊNH</div>
                        </div>
                        <div className="super-title-right">
                            <img src="/images/left.png.avif" style={{ transform: "rotateY(180deg)" }} />
                        </div>
                    </div>
                    <div class="textIs textIn">
                        <div class="superior">
                            <div class="flex jc-s">
                                <div class="meter_item ai-c flex fdr-c">
                                    <div class="meter">
                                        <div class="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
 A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div class="view fdr-c flex-middle">
                                            <div class="num">60</div>
                                            <div class="unit">s</div>
                                        </div>
                                        <div class="flex-middle fdr-c describe">
                                            <p class="title">TRUNG BÌNH</p>
                                            <p class="desc">Thời Gian Nạp Tiền</p>
                                        </div>
                                    </div>
                                    <div class="home_desc fdr-c pointer">
                                        <div class="cg_icon">
                                            <img class="app-image" src="/images/i_assure1.png.avif" loading="lazy" />
                                        </div>
                                        <p class="title">AN TOÀN HƠN</p>
                                        <div class="desc">
                                            Độc quyền phát triển, sử dụng công nghệ mã hóa 128-bit và hệ thống quản lý bảo mật nghiêm ngặt, tiền của khách hàng được bảo vệ toàn vẹn
                                            nhất, giúp bạn tận hưởng trọn vẹn hoạt động giải trí, đặt cược vào các trận đấu và không cần lo lắng!
                                        </div>
                                    </div>
                                </div>
                                <div class="meter_item ai-c flex fdr-c">
                                    <div class="meter">
                                        <div class="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
 A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div class="view fdr-c flex-middle">
                                            <div class="num">80</div>
                                            <div class="unit">Nhà</div>
                                        </div>
                                        <div class="flex-middle fdr-c describe">
                                            <p class="title">HỢP TÁC</p>
                                            <p class="desc">Hỗ Trợ Trang Web</p>
                                        </div>
                                    </div>
                                    <div class="home_desc fdr-c pointer">
                                        <div class="cg_icon">
                                            <img class="app-image" src="/images/i_assure2.png.avif" loading="lazy" />
                                        </div>
                                        <p class="title">CHUYÊN NGHIỆP HƠN</p>
                                        <div class="desc">
                                            Cung cấp cho bạn gần một nghìn môn thể thao thú vị, sự kiện thể thao điện tử mỗi ngày, trải nghiệm thú vị tột đỉnh về cách chơi và nhiều
                                            hình thức giải trí khác như live casino, game bài, lô đề, nổ hũ,… tùy ý thích của bạn chọn.
                                        </div>
                                    </div>
                                </div>
                                <div class="meter_item ai-c flex fdr-c">
                                    <div class="meter">
                                        <div class="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
 A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div class="view fdr-c flex-middle">
                                            <div class="num">90</div>
                                            <div class="unit">s</div>
                                        </div>
                                        <div class="flex-middle fdr-c describe">
                                            <p class="title">TRUNG BÌNH</p>
                                            <p class="desc">Thời Gian Rút Tiền</p>
                                        </div>
                                    </div>
                                    <div class="home_desc fdr-c pointer">
                                        <div class="cg_icon">
                                            <img class="app-image" src="/images/i_assure3.png.avif" loading="lazy" />
                                        </div>
                                        <p class="title">TỐC ĐỘ HƠN</p>
                                        <div class="desc">
                                            Hệ thống xử lý tài chính được nghiên cứu và phát triển độc lập bằng công nghệ mới nhất, thật sự đạt được hiệu quả kỹ thuật, nạp, rút,
                                            chuyển. Công nghệ tối ưu hóa mạng độc quyền.
                                        </div>
                                    </div>
                                </div>
                                <div class="meter_item ai-c flex fdr-c">
                                    <div class="meter">
                                        <div class="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
 A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div class="view fdr-c flex-middle">
                                            <div class="num">19</div>
                                            <div class="unit">Nhà</div>
                                        </div>
                                        <div class="flex-middle fdr-c describe">
                                            <p class="title">HỢP TÁC</p>
                                            <p class="desc">Trang Web Trò Chơi</p>
                                        </div>
                                    </div>
                                    <div class="home_desc fdr-c pointer">
                                        <div class="cg_icon">
                                            <img class="app-image" src="/images/i_assure4.png.avif" loading="lazy" />
                                        </div>
                                        <p class="title">THUẬN TIỆN HƠN</p>
                                        <div class="desc">
                                            Ứng dụng gốc tự nghiên cứu phát triển Web, H5, IOS, Android, giúp bạn tùy thích đặt cược mọi lúc mọi nơi! Cung cấp dịch vụ chăm sóc
                                            khách hàng 24/7, tận tình chăm sóc.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="container">
                    <div className="section-title" onClick={() => navigate("/lottery")}>
                        Live Casino
                    </div>
                    <div className="menu-box-game" style={{ marginTop: "0" }}>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/DG.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/AG.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/WM.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/aeSexy.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-title" onClick={() => navigate("/sports")}>
                        Thể Thao
                    </div>
                    <div className="menu-box-game" style={{ marginTop: "0" }}>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/UG.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/SABA.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/CMD.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/SBO.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>

            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main" style={{ position: "relative" }}>
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "20px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "20px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#007aff", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input" style={{ maxWidth: "400px", margin: "0 auto 20px" }}>
                                    <input
                                        className="ipadd"
                                        type="text"
                                        style={{ width: "100%", borderRadius: "20px", fontSize: "15px", padding: "5px 0 5px 15px", color: "#000" }}
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div
                                        className="absolute-input"
                                        onClick={selectAll}
                                        style={{ fontSize: "13px", padding: "10px 20px", height: "90%", top: "5%", right: "2px", borderRadius: "20px" }}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"} style={{ width: "150px", height: "40px", margin: "0 auto" }}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
            {isShow === true ? (
                <>
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header">{title}</div>
                            <div className="popup-content">
                                <div className="gameapi-list">
                                    {game != null &&
                                        game.map((item) => (
                                            <div className="gameapi-item" onClick={() => launchGame({ product_type: item.productType, game_code: item.tcgGameCode })}>
                                                <img src={`https://images.21947392.com/TCG_GAME_ICONS/${item.productCode}/${item.tcgGameCode}.png`} alt={item.gameName} />
                                                <div className="gameapi-name">{item.gameName}</div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <button onClick={() => setShow(false)} className="popup-close">
                                Đóng
                            </button>
                        </div>
                    </div>
                </>
            ) : null}
            {yoPopup === true && yoGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setYoPopup(false)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">VIVO88</div>
                        <div className="popup-content">
                            <ul className="tab-navigation tab-game">
                                <li className={yoType === 1 ? "active" : ""} onClick={() => setYoType(1)}>
                                    Nạp tiền
                                </li>
                                <li className={yoType === 2 ? "active" : ""} onClick={() => setYoType(2)}>
                                    Rút tiền
                                </li>
                            </ul>
                            <div className="form-sibo">
                                <input
                                    type="text"
                                    value={yoMoney}
                                    onClick={() => setYoMoney(null)}
                                    onChange={(e) => setYoMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    placeholder="Nhập số tiền"
                                />
                                <button onClick={submitYoGame}>Xác nhận</button>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư YoGame: <b>{yoBalance?.toLocaleString("vi-VN")}</b>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <button className="open-game" onClick={() => (window.location.href = `https://game.link-mb66.com?token=${yoGame?.Token}`)}>
                                Vào game
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Home;
